<template>
  <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row  tw-items-center tw-justify-start">
    <div class="seat-panel tw-p-3 lg:tw-p-5 " :style="{minWidth : minWidth}">
      <div class="tw-flex tw-flex-col tw-w-full tw-py-4">
        <div class="tw-flex tw-flex-row  tw-flex-wrap tw-w-full tw-py-4 seat-status tw-items-center tw-justify-between">
          <img src="../../../assets/available_status.svg" alt="available" class="">
          Available &nbsp;&nbsp;
          <img src="../../../assets/selected_status.svg" alt="available" class="">
          Selected &nbsp;&nbsp;
          <img src="../../../assets/pending_status.svg" alt="available" class="">
          Pending &nbsp;&nbsp;
          <img src="../../../assets/booked_status.svg" alt="available" class="">
          Booked &nbsp;&nbsp;
        </div>
      </div>
      <div class="tw-flex tw-w-full  tw-flex-row tw-mb-8">
        <img :src="steering" alt="booked" class="tw-flex tw-items-end" style="width: 40px; height: 40px" />
        <v-spacer/>
        <seat  :seat-number="totalSeats[0].seatNumber ? totalSeats[0].seatNumber : 0" :seat-status="totalSeats[1].status"
               class="tw-flex tw-items-start" @selectedSeat="handleSelectedSeats" />
      </div>

      <div v-if="totalSeats.length === 6" class="tw-flex tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 6)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column  :seats-data="getSeatByRow('secondRow', 6)"
                      @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length === 7" class="tw-flex tw-w-full tw-flex-col">
        <seat-column :single-space="true"  :seats-data="getSeatByRow('firstRow', 7)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :single-space="true"  :seats-data="getSeatByRow('secondRow', 7)"
                     @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length === 8" class="tw-flex tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 8)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column  :seats-data="getSeatByRow('secondRow', 8)"
                      @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 9" class="tw-flex tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 9)"
                      @selectedSeat="handleSelectedSeats"/>
        <back-seat-column  :seats-data="getSeatByRow('backSeat')"
                           @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 12" class="tw-flex tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 12)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :single-space="true" :seats-data="getSeatByRow('secondRow', 12)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')" @selectedSeat="handleSelectedSeats" />
      </div>

      <div v-if="totalSeats.length === 14" class="tw-flex  tw-w-full tw-flex-col ">
        <seat-column  :seats-data="getSeatByRow('firstRow', 14)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :single-space="true" :seats-data="getSeatByRow('secondRow', 14)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :single-space="true" :seats-data="getSeatByRow('thirdRow', 14)"
                     v-if="totalSeats.length> 12" @selectedSeat="handleSelectedSeats"/>

        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length === 15" class="tw-flex  tw-w-full tw-flex-col ">
        <seat-column  :seats-data="getSeatByRow('firstRow', 15)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :single-space="true" :seats-data="getSeatByRow('secondRow', 15)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :single-space="true" :seats-data="getSeatByRow('thirdRow', 15)"
                     v-if="totalSeats.length> 12" @selectedSeat="handleSelectedSeats"/>

        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 18" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 18)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :single-space="true" :seats-data="getSeatByRow('secondRow', 18)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :single-space="true" :seats-data="getSeatByRow('thirdRow', 18)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :single-space="true"  :seats-data="getSeatByRow('fourthRow', 18)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 22" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column :double-space="true" :seats-data="getSeatByRow('firstRow', 22)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 22)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 22)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 22)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fifthRow', 22)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length === 24" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 24)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 24)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 24)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 24)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 24)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 30" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 30)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 30)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 30)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 30)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 30)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('sixthRow', 30)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('seventhRow', 30)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length === 32" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 32)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 32)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 32)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 32)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 32)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('sixthRow', 32)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('seventhRow', 32)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length === 34" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column :double-space="true" :seats-data="getSeatByRow('firstRow', 34)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 34)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 34)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 34)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 34)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('sixthRow', 34)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('seventhRow', 34)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length === 35" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column :double-space="true" :seats-data="getSeatByRow('firstRow', 35)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 35)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 35)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 35)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 35)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('sixthRow', 35)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('seventhRow', 35)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eighthRow', 35)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 40" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column :double-space="true" :seats-data="getSeatByRow('firstRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('sixthRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('seventhRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eighthRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('ninthRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('tenthRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 45" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column :double-space="true" :seats-data="getSeatByRow('firstRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('sixthRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('seventhRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eighthRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('ninthRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('tenthRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eleventhRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 50" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column :double-space="true" :seats-data="getSeatByRow('firstRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('sixthRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('seventhRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eighthRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('ninthRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('tenthRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eleventhRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('twelfthRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length === 52" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column :double-space="true" :seats-data="getSeatByRow('firstRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('sixthRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('seventhRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eighthRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('ninthRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('tenthRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eleventhRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat',52)"
                          @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length >= 53" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column :double-space="true" :seats-data="getSeatByRow('firstRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('sixthRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('seventhRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eighthRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('ninthRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('tenthRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eleventhRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('twelfthRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <!--        <back-seat-column :seats-data="getSeatByRow('backSeat')"-->
        <!--                          @selectedSeat="handleSelectedSeats"/>-->
      </div>
    </div>
  </div>
</template>

<script>
import camera from '@/assets/camera.png'
import availableSeat from '@/assets/booked.svg'
import selectedSeat from '@/assets/selected.svg'
import bookedSeat from '@/assets/available.svg'
import pendingSeat from '@/assets/pendingSeat.svg'
import steering from '@/assets/steering.svg'
import Seat from "@/components/reuseables/booking/Seat";
import BackSeatColumn from "@/components/reuseables/booking/BackSeatColumn";
import SeatColumn from "@/components/reuseables/booking/SeatColumn";
import {mapGetters} from "vuex";
export default {
  name: "VehicleSeats",
  components: {SeatColumn, BackSeatColumn, Seat},
  props:{
    trip:Object
  },
  data(){
    return{
      camera,
      availableSeat,
      selectedSeat,
      pendingSeat,
      bookedSeat : bookedSeat,
      steering :steering,
      minWidth: "100%",
      totalSeats:[],
      selectedSeats :[],
    }
  },
  watch:{
    trip:{
      handler : function (val){
        if (val && val.fleetForThisTrip.seats.length){
          this.availableSeats = []
          val.fleetForThisTrip.seats.forEach(seat => {
            if (seat.status === 'AVAILABLE'){
              this.availableSeats.push(seat)
            }
            this.totalSeats = val.fleetForThisTrip.seats
          })

        }
      },
      immediate: true,
      deep : true
    }
  },
  computed:{
    ...mapGetters("booking",["oneWayTotalAmount"])
  },
  methods:{
    getWith(seatLength){
      if(seatLength > 18 && this.$vuetify.breakpoint.smAndDown){
        this.minWidth = "100%"
      }
      else if(seatLength > 18 && this.$vuetify.breakpoint.mdAndUp){
        this.minWidth = "25rem"
      }
      else if(seatLength < 18 && this.$vuetify.breakpoint.mdAndUp){
        this.minWidth = "23.75rem"
      }
      else if(seatLength < 18 && this.$vuetify.breakpoint.smAndDown){
        this.minWidth = "100%"
      }
    },
    handleSelectedSeats(clickSeat){
      let tripDetail = {}
      let uniqueSeats = new Set()
      tripDetail.tripId = this.trip.id
      tripDetail.seats = clickSeat
      // console.log(tripDetail, "clicked seat")
      this.$store.dispatch("booking/getTotalAmount",this.trip.tripAmount)
      tripDetail.tripAmount = this.oneWayTotalAmount
      this.trip.fleetForThisTrip.seats.forEach(seat => {
        if (seat.seatNumber === clickSeat.seatNumber){
          seat.seatstatus = clickSeat.seatStatus
        }
      })
      clickSeat.forEach(seat => uniqueSeats.add(JSON.stringify(seat)))
      tripDetail.seats = []
      uniqueSeats.forEach(seat => tripDetail.seats.push(JSON.parse(seat)) )
      sessionStorage.setItem("tripDetails",JSON.stringify(tripDetail))
      this.$store.dispatch("booking/updateTripAndTravellerDetails",tripDetail)
      this.$emit('getDetails',tripDetail)
      // console.log(tripDetail, "trace heer")

    },
    getSeatByRow(rowType, seater){
      let rowData = [];
      // siena starts 6
      if (rowType === "firstRow" && seater === 6){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) > 1 && Number.parseInt(seat.seatNumber) < 4){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 6){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) >3 && Number.parseInt(seat.seatNumber) < 7){
            rowData.push(seat)
          }
        })
      }
      // siena starts 7
      if (rowType === "firstRow" && seater === 7){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 4){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 7){
        this.totalSeats.forEach(seat =>{

          if (seat.seatNumber >3 && seat.seatNumber < 8){
            rowData.push(seat)
          }
        })
      }
      if (rowType === "firstRow" && seater === 8){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 8){
        this.totalSeats.forEach(seat =>{

          if (seat.seatNumber >4 && seat.seatNumber < 9){
            rowData.push(seat)
          }
        })
      }

      if (rowType === "firstRow" && seater === 9){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 6){
            rowData.push(seat)
          }
        })
      }
          // siena ends

      // 12 seater starts
      else if (rowType === "firstRow" && seater > 11 && seater <14 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater > 11 && seater <14 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >4 && seat.seatNumber < 8){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater > 11 && seater <14  ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >7 && seat.seatNumber < 12){
            rowData.push(seat)
          }
        })
      }
          // 12 seater ends

      // 14 seater start
      else if (rowType === "firstRow" && seater === 14 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 14 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >4 && seat.seatNumber < 8){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 14 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >7 && seat.seatNumber < 11){
            rowData.push(seat)
          }
        })
      }
          // 14 seater ends

      // 15 seater starts
      else if (rowType === "firstRow" && seater === 15 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 15 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >4 && seat.seatNumber < 8){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 15 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >7 && seat.seatNumber < 12){
            rowData.push(seat)
          }
        })
      }
          // 15 seater ends
      // 18 seater starts
      else if (rowType === "firstRow" && seater === 18 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 18 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >4 && seat.seatNumber < 8){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 18 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >7 && seat.seatNumber < 11){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 18 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >10 && seat.seatNumber < 15){
            rowData.push(seat)
          }
        })
      }
          // 18 seater ends
      // 22 seater start
      else if (rowType === "firstRow" && seater === 22 && this.totalSeats.length >= 22){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 22 && this.totalSeats.length >= 22){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 8){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 22 && this.totalSeats.length >= 22){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 7 && seat.seatNumber < 11){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 22 && this.totalSeats.length >= 22){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 10 && seat.seatNumber < 15){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 22 && this.totalSeats.length >= 22){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 14 && seat.seatNumber < 19){
            rowData.push(seat)
          }
        })
      }

          // 22 seater ends
      // 24 seater starts
      else if (rowType === "firstRow" && seater === 24 && this.totalSeats.length >= 24){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 24 && this.totalSeats.length >= 24){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 9){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 24 && this.totalSeats.length >= 24){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 8 && seat.seatNumber < 13){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 24 && this.totalSeats.length >= 24){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 12 && seat.seatNumber < 17){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 24 && this.totalSeats.length >= 24){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 16 && seat.seatNumber < 21){
            rowData.push(seat)
          }
        })
      }
          // 24 seater ends
      // 30 seater starts
      else if (rowType === "firstRow" && seater === 30 && this.totalSeats.length >= 30){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 30 && this.totalSeats.length >= 30){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 8){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 30 && this.totalSeats.length >= 30){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 7 && seat.seatNumber < 11){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 30 && this.totalSeats.length >= 30){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 10 && seat.seatNumber < 15){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 30 && this.totalSeats.length >= 30){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 14 && seat.seatNumber < 19){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "sixthRow" && seater === 30 && this.totalSeats.length >= 30){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 18 && seat.seatNumber < 23){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "seventhRow" && seater === 30 && this.totalSeats.length >= 30){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 22 && seat.seatNumber < 27){
            rowData.push(seat)
          }
        })
      }

          // 30 seater ends
      // 32 seater starts
      else if (rowType === "firstRow" && seater === 32 && this.totalSeats.length >= 32){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 32 && this.totalSeats.length >= 32){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 9){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 32 && this.totalSeats.length >= 32){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 8 && seat.seatNumber < 13){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 32 && this.totalSeats.length >= 32){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 12 && seat.seatNumber < 17){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 32 && this.totalSeats.length >= 32){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 16 && seat.seatNumber < 21){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "sixthRow" && seater === 32 && this.totalSeats.length >= 32){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 20 && seat.seatNumber < 25){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "seventhRow" && seater === 32 && this.totalSeats.length >= 32){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 24 && seat.seatNumber < 29){
            rowData.push(seat)
          }
        })
      }
          // 32 seater ends
      // 34 seater starts
      else if (rowType === "firstRow" && seater === 34 && this.totalSeats.length >= 34){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 34 && this.totalSeats.length >= 34){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 8){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 34 && this.totalSeats.length >= 34){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 7 && seat.seatNumber < 11){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 34 && this.totalSeats.length >= 34){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 10 && seat.seatNumber < 15){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 34 && this.totalSeats.length >= 34){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 14 && seat.seatNumber < 19){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "sixthRow" && seater === 34 && this.totalSeats.length >= 34){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 18 && seat.seatNumber < 23){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "seventhRow" && seater === 34 && this.totalSeats.length >= 34){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 22 && seat.seatNumber < 27){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eighthRow" && seater === 34 && this.totalSeats.length >= 34){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 26 && seat.seatNumber < 31){
            rowData.push(seat)
          }
        })
      }
          // 34 seater ends
      // 35 seater starts
      else if (rowType === "firstRow" && seater === 35 && this.totalSeats.length >= 35){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 35 && this.totalSeats.length >= 35){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 8){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 35 && this.totalSeats.length >= 35){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 7 && seat.seatNumber < 12){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 35 && this.totalSeats.length >= 35){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 11 && seat.seatNumber < 16){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 35 && this.totalSeats.length >= 35){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 15 && seat.seatNumber < 20){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "sixthRow" && seater === 35 && this.totalSeats.length >= 35){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 19 && seat.seatNumber < 24){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "seventhRow" && seater === 35 && this.totalSeats.length >= 35){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 23 && seat.seatNumber < 28){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eighthRow" && seater === 35 && this.totalSeats.length >= 35){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 27 && seat.seatNumber < 32){
            rowData.push(seat)
          }
        })
      }
          // 35 ends

      // 40 starts
      else if (rowType === "firstRow" && seater === 40 && this.totalSeats.length >= 40){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 40 && this.totalSeats.length >= 40){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 9){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 40 && this.totalSeats.length >= 40){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 8 && seat.seatNumber < 13){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 40 && this.totalSeats.length >= 40){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 12 && seat.seatNumber < 17){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 40 && this.totalSeats.length >= 40){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 16 && seat.seatNumber < 21){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "sixthRow" && seater === 40 && this.totalSeats.length >= 40){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 20 && seat.seatNumber < 26){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "seventhRow" && seater === 40 && this.totalSeats.length >= 40){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 25 && seat.seatNumber < 31){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eighthRow" && seater === 40 && this.totalSeats.length >= 40){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 30 && seat.seatNumber < 36){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "ninthRow" && seater === 40 && this.totalSeats.length >= 40){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 35 && seat.seatNumber < 41){
            rowData.push(seat)
          }
        })
      }
          // 40 ends

      // 45 starts
      else if (rowType === "firstRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 9){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 8 && seat.seatNumber < 13){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 12 && seat.seatNumber < 17){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 16 && seat.seatNumber < 21){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "sixthRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 20 && seat.seatNumber < 26){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "seventhRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 25 && seat.seatNumber < 31){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eighthRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 30 && seat.seatNumber < 36){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "ninthRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 35 && seat.seatNumber < 41){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "tenthRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 40 && seat.seatNumber < 46){
            rowData.push(seat)
          }
        })
      }
          // 45 ends

      // 50 starts
      else if (rowType === "firstRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 9){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 8 && seat.seatNumber < 13){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 12 && seat.seatNumber < 17){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 16 && seat.seatNumber < 21){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "sixthRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 20 && seat.seatNumber < 26){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "seventhRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 25 && seat.seatNumber < 31){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eighthRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 30 && seat.seatNumber < 36){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "ninthRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 35 && seat.seatNumber < 41){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "tenthRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 40 && seat.seatNumber < 46){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eleventhRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 45 && seat.seatNumber < 51){
            rowData.push(seat)
          }
        })
      }
          // 50 ends

      //52 starts
      else if (rowType === "firstRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 9){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 8 && seat.seatNumber < 13){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 12 && seat.seatNumber < 17){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 16 && seat.seatNumber < 21){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "sixthRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 20 && seat.seatNumber < 25){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "seventhRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 24 && seat.seatNumber < 29){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eighthRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 28 && seat.seatNumber < 34){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "ninthRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 33 && seat.seatNumber < 39){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "tenthRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 38 && seat.seatNumber < 44){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eleventhRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 43 && seat.seatNumber < 49){
            rowData.push(seat)
          }
        })
      }
          // 52 end

      //53 starts
      else if (rowType === "firstRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 9){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 8 && seat.seatNumber < 13){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 12 && seat.seatNumber < 17){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 16 && seat.seatNumber < 21){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "sixthRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 20 && seat.seatNumber < 25){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "seventhRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 24 && seat.seatNumber < 29){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eighthRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 28 && seat.seatNumber < 34){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "ninthRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 33 && seat.seatNumber < 39){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "tenthRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 38 && seat.seatNumber < 44){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eleventhRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 43 && seat.seatNumber < 49){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "twelfthRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 48 && seat.seatNumber < 54){
            rowData.push(seat)
          }
        })
      }
          // 53 ends

      // 35 seater ends
      else if (rowType === "backSeat" ){
        rowData = this.totalSeats.slice(this.totalSeats.length - 4)
      }
      else if (rowType === "custom"){
          rowData = this.totalSeats
        }
      return rowData;
    },
  },
  created() {
    let tripDetails = JSON.parse(sessionStorage.getItem("tripDetails"))
    let userSelectedTrip = JSON.parse(sessionStorage.getItem("userSelectedTrip"))
    let oneWaySeats = JSON.parse(sessionStorage.getItem("oneWaySeats"))
    if(tripDetails && Object.keys(tripDetails).length){
      tripDetails.seats.forEach(storeSeat => {
        this.trip.fleetForThisTrip.seats.forEach(seat => {
          if (seat.seatNumber === storeSeat.seatNumber){
            seat.status = storeSeat.seatStatus
          }
        })
        this.$store.dispatch("booking/addSeat",storeSeat)
      })
      this.$store.dispatch("booking/getTotalAmount",userSelectedTrip.tripAmount)
      this.$store.dispatch("booking/updateTripAndTravellerDetails",tripDetails)
    }
    let uniqueSeats = []
    if (oneWaySeats && oneWaySeats.length){
       oneWaySeats.forEach(seat =>{
         if(uniqueSeats.length){
           uniqueSeats.forEach(uniqueSeat =>{
             if (uniqueSeat.seatNumber !== seat.seatNumber){uniqueSeats.push(seat)}})}
         else {uniqueSeats.push(seat)}})
    }
    uniqueSeats.forEach(seat => this.$store.dispatch("booking/addSeat",seat))
  }
}
</script>

<style scoped lang="scss">
.seat-panel{
  background: #FDFFFC;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  min-width: 250px;
  width: max-content;
  height: max-content;

  @media screen  and (max-width: 1024px){
    margin-top: 20px;
  }
}
.column{
  display: flex;
  flex-direction: column;

}
</style>